import React, { useContext, useRef, useState } from 'react';
import { collectError, isObject, isString } from 'utils';
import { FormikValidationResult } from 'types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { activateFXSession, loginRequest } from '../../../../api/fx';
import { CxLoginRequestBody, LastLogin, Organisation } from '../../../../api/types';
import { getAppTypeFromResponseHeaders } from '../../../../common/utils/appType';
import { getDataFromForm } from '../../shared/form';
import { getJwtErrorMessage } from '../../shared/utils';
import { loginToCXAuthRequest } from '../../../../api/cx';
import { loginToOrganisation } from '../../shared/loginToOrganisation';
import { metaContext } from '../../../meta/constants';
import { setAppView } from '../../shared/view';
import { validateLoginForm } from './validation';
import LoginForm from '../../components/LoginForm';
import router, { FormRoutes, getParam } from '../../../../common/utils/routing';

interface LoginFormContainerProps {
    globalError?: React.ReactElement | string;
    setBeta: React.Dispatch<React.SetStateAction<boolean | null>>;
    setConfirmationTimeHasExpired: React.Dispatch<React.SetStateAction<boolean>>;
    setGlobalError: React.Dispatch<React.SetStateAction<React.ReactElement | string>>;
    setLastLogin: React.Dispatch<React.SetStateAction<LastLogin>>;
    setOrganisations: (organisation: Organisation[]) => void;
    setOrganisationsIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const LoginFormContainer: React.FC<LoginFormContainerProps> = ({
    globalError,
    setBeta,
    setConfirmationTimeHasExpired,
    setGlobalError,
    setLastLogin,
    setOrganisations,
    setOrganisationsIds,
}) => {
    const passwordRef = useRef<HTMLInputElement>(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>(getJwtErrorMessage);
    const [fieldErrors, setFieldError] = useState<Record<string, FormikValidationResult>>({});
    const { executeRecaptcha } = useGoogleReCaptcha();
    const {
        data: { appType },
        updateMeta,
    } = useContext(metaContext);

    async function submitLoginToFXAuth(body: CxLoginRequestBody) {
        const captcha = await executeRecaptcha!();
        const { mfa, message, organisationIds } = await loginRequest({
            ...body,
            captcha,
        });

        if (message) {
            throw new Error(message);
        }

        setOrganisationsIds(organisationIds);

        if (mfa) {
            window.TEG?.mfa?.verify({
                functionName: 'Log in',
                isLightMode: true,
                onClose: () => {
                    if (passwordRef.current) {
                        passwordRef.current.value = '';
                    }
                },
                onSuccess: async () => {
                    let lastLogin: LastLogin = null;

                    try {
                        await activateFXSession();
                    } catch (error) {
                        // TODO: if status 409, then save lastLogin, if >=500 then skip
                        lastLogin = error as LastLogin;
                    }

                    if (lastLogin) {
                        setLastLogin(lastLogin);
                        return router.go(FormRoutes.currentSession);
                    }

                    await loginToOrganisation(setOrganisations, organisationIds);
                },
            });

            return;
        }

        let lastLogin: LastLogin = null;

        try {
            await activateFXSession();
        } catch (error: unknown) {
            // TODO: if status 409, then save lastLogin, if >=500 then skip
            lastLogin = error as LastLogin;
        }

        if (lastLogin) {
            setLastLogin(lastLogin);
            return router.go(FormRoutes.currentSession);
        }

        try {
            await loginToOrganisation(setOrganisations, organisationIds);
        } catch (error: unknown) {
            collectError(error);
            window.location.assign('/');
        }
    }

    async function submitLoginToCXAuth(data: CxLoginRequestBody) {
        const res = await loginToCXAuthRequest(data);
        const {
            beta,
            confirmationTimeHasExpired,
            lastLogin,
            message,
            passwordChangeRequired,
            passwordVerificationRequired,
            redirectUrl,
        } = res.data;

        updateMeta(getAppTypeFromResponseHeaders(res, appType));

        if (message) {
            throw new Error(message);
        }

        if (passwordVerificationRequired) {
            return router.go(FormRoutes.checkInboxError);
        }

        if (passwordChangeRequired) {
            confirmationTimeHasExpired && setConfirmationTimeHasExpired(confirmationTimeHasExpired);
            return router.go(FormRoutes.legacyPassword);
        }

        if (beta) {
            setBeta(beta);
            return submitLoginToFXAuth(data);
        }

        if (redirectUrl) {
            return window.location.assign(redirectUrl);
        }

        if (lastLogin) {
            setLastLogin(lastLogin);
            return router.go(FormRoutes.currentSession);
        }
    }

    async function onSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
        event.preventDefault();
        setAppView(event.nativeEvent);

        const data = getDataFromForm<CxLoginRequestBody>(event);
        const { errors, isValid, isFormReset } = validateLoginForm(data);

        if (!isValid) {
            setFieldError(errors);
            setError(undefined);

            if (isFormReset) {
                event.currentTarget.reset();
            }

            return;
        }

        try {
            setSubmitting(true);
            await submitLoginToCXAuth(data);
        } catch (error: unknown) {
            setGlobalError('');
            collectError(error);

            // clear password field
            // eslint-disable-next-line no-param-reassign
            (event.target as any)['1'].value = '';

            if (isString(error)) {
                return setError(error);
            }

            if (isObject(error) && 'message' in error) {
                return setError(error.message as string | undefined);
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <LoginForm
            ref={passwordRef}
            onSubmit={onSubmit}
            formError={globalError || error}
            isSubmitting={isSubmitting}
            switchToForgetPassword={() => router.go(FormRoutes.forgotPassword)}
            errors={fieldErrors}
            message={getParam('message')}
        />
    );
};

export default LoginFormContainer;
